import React, { useEffect } from "react";
import "./profile360.scss";
import CurrentReservation from "./currentReservation ";
import Preferences from "./Preferences";
import UserProfile from "./userProfile";
import PurposefulEngagement from "./purposefulEngagement";
import Custom360modal from "./common/modal";
import IssuesOrNotes from "./issuesOrNotes";
import { useLocation, useSearchParams } from "react-router-dom";
import NavSearchBar from "../../Layout/Header/NavSearchBar";
function Base() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const commonProps = { email: "", reservationId: "" };
  commonProps.email = params.get("e") ? atob(params.get("e")) : "";
  commonProps.reservationId = params.get("i") ? atob(params.get("i")) : "";

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.set("profilePage", true);
    setSearchParams(searchParams, {
      replace: true,
      preventScrollReset: true,
    });
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className="profile-360"
    >
      <div className="profile-360-base">
        <div className="flex gap-26px justify-center flex-wrap">
          <div className="flex gap-26px justify-center items-start profile-Preference-wrap flex-wrap-reverse">
            <div className="flex flex-col gap-26px">
              <CurrentReservation {...commonProps} />
              <Preferences {...commonProps} />
            </div>
            <UserProfile {...commonProps} />
          </div>
          <div className="purposeful-notes-wrapper">
            <PurposefulEngagement />
            <IssuesOrNotes />
          </div>
        </div>
        <Custom360modal {...commonProps}/>
      </div>
    </div>
  );
}

export default Base;
