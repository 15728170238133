import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  profile360_actions,
  profile360Selector,
} from "../../Component/Profile360/js/slice";
import { useSelector } from "react-redux";
import { Input, InputGroup } from "reactstrap";

export default function NavSearchBar() {
  const dispatch = useDispatch();
  const { pathname, key } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const timeLineSearch = useSelector(profile360Selector.searchByTimeLine);

  const handleChange = (event) => {
    dispatch(profile360_actions.setSearchValue(event.target?.value));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    searchParams.set(
      "i",
      timeLineSearch.value ? btoa(timeLineSearch.value) : ""
    );
    searchParams.set("e", "");
    searchParams.set("lN_slt_skip", "");
    searchParams.set("linkedInUrl", "");
    setSearchParams(searchParams, {
      replace: true,
      preventScrollReset: true,
    });
    dispatch(profile360_actions.triggerSearch(key));
  };

  if (pathname.includes("profile-360")) {
    return (
      <div style={{ float: "right", padding: "0px" }} className="social-tab">
        <form onSubmit={handleSearch}>
          <InputGroup style={{width:'600px'}}>
            <Input
              className="form-control"
              type="text"
              onChange={handleChange}
              value={timeLineSearch.value}
              placeholder="Search......."
            />
            <span className="input-group-text cursor_pointer">
              <i className="fa fa-search" onClick={handleSearch}></i>
            </span>
          </InputGroup>
        </form>
      </div>
    );
  } else {
    return <></>;
  }
}
