import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./index.scss";
import { Profile360_icon } from "../common/icons";
import { Profile360Apis } from "../js/api";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { profile360_actions, profile360Selector } from "../js/slice";
import { Backdrop, Skeleton } from "@mui/material";
import Loader from "../../../CommonElements/Loader";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Confirm from "../common/confirm";
import NavSearchBar from "../../../Layout/Header/NavSearchBar";

const Profile = ({
  reservationId = "",
  data = {},
  skelton = false,
  cBackFun = () => {},
  disableAction = false,
}) => {
  const {
    firstName = "-",
    lastName = "",
    profilePicture = "",
    profileURL = "",
    location = "-",
    company = {},
  } = data;
  const { companyName = "-", companyLogo = "", title = "" } = company;
  const showFeature = false; // for hide some styles for now !
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {" "}
      <div
        onClick={() => {
          if (disableAction) return;
          setIsOpen(true);
        }}
        className={`profile ${!skelton && !disableAction && `cursor-pointer`}`}
      >
        <div className="relative flex justify-center pb-5px">
          {showFeature && (
            <div className="absolute top-0 right-0 matcher flex justify-center flex-col">
              <div className="flex flex-nowrap big gap-2px mt-8px ml-auto mr-8px">
                75
                <div className="small mt-auto">%</div>
              </div>
              <div className="small ml-14px">Match</div>
            </div>
          )}
          {skelton ? (
            <Skeleton
              variant="circular"
              className="mt-49px"
              width={286}
              height={286}
            />
          ) : (
            <img
              className="img mt-49px"
              src={profilePicture || "/profile_360.png"}
              alt=""
            />
          )}

          {showFeature && (
            <div className=" absolute bottom-0 vip flex items-center justify-center">
              VIP 1
            </div>
          )}
        </div>
        <div className="flex items-center flex-col">
          <div className="title-1">
            {skelton ? (
              <div className="flex flex-nowrap gap-6px">
                <Skeleton variant="rounded" width={95} height={24} />
                <Skeleton variant="rounded" width={70} height={24} />
              </div>
            ) : (
              <>
                {firstName}&nbsp;{lastName}
              </>
            )}
          </div>
          <div className="company flex items-center justify-center ">
            {skelton ? (
              <Skeleton variant="circular" width={24} height={24} />
            ) : companyLogo ? (
              <img
                className="w-30px h-30px radius-full"
                src={companyLogo}
                alt="company logo"
              />
            ) : (
              <div
                style={{ background: "#dddddd" }}
                className="w-24px h-24px radius-full"
              />
            )}
            <div className="ml-8px mt-3px">
              {skelton ? (
                <Skeleton variant="rounded" width={64} height={18} />
              ) : (
                companyName
              )}
            </div>
          </div>
          <div className="location text-center mt-21px w-330px flex justify-center">
            {skelton ? (
              <Skeleton
                className="ml-auto mr-auto"
                variant="rounded"
                width={270}
                height={20}
              />
            ) : (
              <>
                {title && companyName ? (
                  <>
                    {title} at {companyName}
                    <br />
                  </>
                ) : (
                  title && (
                    <>
                      {title}
                      <br />
                    </>
                  )
                )}

                {location}
              </>
            )}
          </div>
        </div>
        {showFeature && (
          <div className="mt-15px mb-9px h-60px flex flex-col justify-between">
            <div className="underline" />
            <div className=" flex justify-between email-phone">
              <div className="flex items-center gap-8px">
                <Profile360_icon.email />
                <div className="mt-3px">-</div>
              </div>

              <div className="flex items-center gap-8px">
                <Profile360_icon.phone />
                <div className="mt-3px"></div>
              </div>
            </div>
            <div className="underline" />
          </div>
        )}
      </div>
      <Confirm
        description="Are you sure you want to select the profile?"
        toggle={() => setIsOpen(false)}
        cback={() => {
          dispatch(
            Profile360Apis.attachLinkedInUrl({
              reservationId,
              linkedInUrl: profileURL,
              cBackFun,
            })
          );
          setIsOpen(false);
        }}
        isOpen={isOpen}
      />
    </>
  );
};

function GlobalSearch({ setHIdeLinkedInProfiles = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  let reservationId = params.get("i") ? atob(params.get("i")) : "";
  const [searchParams, setSearchParams] = useSearchParams();
  const profilesFromLinkedIn = useSelector(
    profile360Selector.profilesFromLinkedIn
  );
  const attachLinkedInUrl = useSelector(profile360Selector.attachLinkedInUrl);

  const cBackFun = () => {
    setHIdeLinkedInProfiles((prev) => {
      if (prev == "KEY_SIMILAR_PROFILE_BTN") {
        return "KEY_SIMILAR_PROFILE_BTN";
      }
      return "KEY_LI_API_FAIL";
    });
  };

  useEffect(() => {
    dispatch(
      Profile360Apis.getProfilesFromLinkedIn({
        reservationId,
        cBackFun,
      })
    );
  }, [reservationId]);

  useEffect(() => {
    searchParams.set("profilePage", "");
    setSearchParams(searchParams, {
      replace: true,
      preventScrollReset: true,
    });
    dispatch(profile360_actions.resetProfileData());
  }, []);

  const profiles = useMemo(() => {
    if (Array.isArray(profilesFromLinkedIn?.data?.profiles)) {
      return profilesFromLinkedIn?.data?.profiles;
    }
    return [];
  }, [profilesFromLinkedIn?.data]);

  function selectPP() {
    let hotelCode = localStorage.getItem("hotelcode");
    switch (hotelCode) {
      case "shmv":
        return "./images/shashi-profile.svg";
      case "tnpa":
        return "./images/nest-profile.svg";
      default:
        return "";
    }
  }

  const StaticProfile = () => {
    return [1, 2, 3].map((vlue, idx) => (
      <Profile
        disableAction={true}
        key={idx}
        data={{ profilePicture: selectPP() }}
      />
    ));
  };

  return (
    <>
      <div className="profile-360">
        <div className="flex justify-center" style={{ paddingTop: "30px" }}>
          <NavSearchBar />
        </div>
        <div className="profile-360-base">
          <div className="profile-global-search w-full flex flex-col items-center">
            <div className="ttle mt-5px mb-35px">
              {profilesFromLinkedIn.loader
                ? `Similar Profile `
                : profiles.length > 0
                ? "Similar Profile found"
                : `Similar Profile Not found`}
            </div>
            <div className="flex flex-row gap-30px flex-wrap justify-center">
              {!reservationId ? (
                <StaticProfile />
              ) : profilesFromLinkedIn.loader ? (
                <>
                  <Profile skelton={true} />
                  <Profile skelton={true} />
                  <Profile skelton={true} />
                </>
              ) : profiles?.length > 0 ? (
                <>
                  {profiles?.map((data, idx) => {
                    return (
                      <Fragment key={idx}>
                        <Profile
                          cBackFun={cBackFun}
                          reservationId={reservationId}
                          data={data}
                        />
                      </Fragment>
                    );
                  })}
                </>
              ) : (
                <StaticProfile />
              )}
            </div>
          </div>
        </div>
        {!profilesFromLinkedIn.loader && reservationId && (
          <div
            onClick={() => {
              dispatch(Profile360Apis.skipLinkedInSelection({ reservationId }));
              setHIdeLinkedInProfiles("KEY_SKIP_LINKEDIN_PROFILE");
            }}
            className="skip-profile-search"
          >
            <div>Skip</div> <ArrowForwardIcon fontSize="small" />
          </div>
        )}
      </div>
      <Backdrop style={{ zIndex: 10 }} open={attachLinkedInUrl.loader}>
        <Loader />
      </Backdrop>
    </>
  );
}

export default GlobalSearch;
