import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { Close, SaveChanges } from "../../../../Constant/index";

const IotModal = (props) => {
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        size={props.size}
        centered
      >
        <ModalHeader toggle={props.toggler}>
          <div>
            <div className="fw-lighter h1 m-0 " style={{ fontSize: "21px" }}>
              {props.title}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              {props.title2 && props.title2}
              {props.title3 && (
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "360px",
                      marginLeft: "12px",
                      marginRight: "12px",
                    }}
                  ></div>
                  <span
                    className={
                      props.title3 === "In-House"
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {props.title3}
                  </span>
                </div>
              )}
              {props.title4 && (
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "360px",
                      marginLeft: "12px",
                      marginRight: "12px",
                    }}
                  ></div>
                  <span
                    className={
                      props.title4 == "none"
                        ? "text-success"
                        : props.title4 === "presence"
                        ? "text-danger"
                        : null
                    }
                  >
                    {props.title4 == "none"
                      ? "Empty Room"
                      : props.title4 === "presence"
                      ? "Guest In Room"
                      : null}
                  </span>
                </div>
              )}
            </div>
          </div>
        </ModalHeader>
        <ModalBody className={props.bodyClass}>{props.children}</ModalBody>
        {props.disabledButtombuttons ? null : (
          <ModalFooter>
            <Btn attrBtn={{ color: "secondary", onClick: props.toggler }}>
              {Close}
            </Btn>
            <Btn attrBtn={{ color: "primary", onClick: props.toggler }}>
              {SaveChanges}
            </Btn>
          </ModalFooter>
        )}
      </Modal>

      <style jsx>
        {`
          .modal-content {
            border-radius: 0 !important;
            min-width: 400px !important;
            width: 25vw !important;
          }
        `}
      </style>
    </>
  );
};

export default IotModal;