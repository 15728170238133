export default {
  modal: {
    isOpen: false,
    isFor: "",
    title: "",
    data: {},
  },
  reservationDetails: {
    data: {},
    loader: false,
  },
  preferenceDetails: {
    data: {},
    loader: false,
    formData: {},
  },
  queryDetails: {
    reservationId: "",
    email: "",
    allowpage: false,
  },
  weather: {
    data: {},
    loader: false,
  },
  profile: {
    data: {},
    loader: false,
  },
  enroll: {
    loader: false,
  },
  userRegistration: {
    loader: false,
  },
  hotelData: {},
  saveCards: {
    loader: false,
  },
  mobileKeySetup: {
    loader: false,
    apiSuccess: false,
  },
  purposefulEngagement: {
    loader: false,
    data: {},
  },
  upgradeRoom: {
    loader: false,
  },
  saveHistory: {
    loader: false,
  },
  addPackagesToReservation: {
    loader: false,
  },
  newservicerequest: {
    loader: false,
  },
  findHistory: {
    loader: false,
    data: [],
  },
  getAllRoomService: {
    loader: false,
    data: {},
  },
  saveUserPreference: {
    loader: false,
  },
  hotelEngagementLogs: {
    loader: false,
    data: {},
  },
  saveHotelEngagementLogs: {
    loader: false,
  },
  profilesFromLinkedIn: {
    loader: false,
    data:{}
  },
  attachLinkedInUrl: {
    loader: false,
    data:{}
  },
  detachLinkedInUrl: {
    loader: false
  },
  skipLinkedInSelection: {
    loader:false
  },
  searchByTimeLine: {
    search: null,
    value:""
  }
};
