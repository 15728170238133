import GlobalSearch from "../../Component/Profile360/profileGlobalSearch";
import Profile360 from "../../Component/Profile360";
import { useLocation, useSearchParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import CheckContext from "../../_helper/customizer/index";
import { useSelector } from "react-redux";
import {
  profile360_actions,
  profile360Selector,
} from "../../Component/Profile360/js/slice";
import { useDispatch } from "react-redux";
function CustomerProfile360() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { hideLinkedInProfiles, setHIdeLinkedInProfiles } =
    useContext(CheckContext);
  const timeLineSearch = useSelector(profile360Selector.searchByTimeLine);
  let profileFlag = params.get("linkedInUrl")
    ? atob(params.get("linkedInUrl"))
    : "";
  let isLinkedInSelectionSkip = params.get("lN_slt_skip")
    ? atob(params.get("lN_slt_skip"))
    : "";
  const searchByTimeLine = useSelector(profile360Selector.searchByTimeLine);
  useEffect(() => {
    setHIdeLinkedInProfiles("KEY_DEFAULT");
  }, []);

  useEffect(() => {
    if (!params.get("i") && timeLineSearch?.value) {
      searchParams.set("i", btoa(timeLineSearch?.value));
      searchParams.set("e", "");
      searchParams.set("lN_slt_skip", "");
      searchParams.set("linkedInUrl", "");
      setSearchParams(searchParams);
    } else if (params.get("i")) {
      dispatch(profile360_actions.setSearchValue(atob(params.get("i"))));
    }
  }, []);

  if (
    profileFlag ||
    hideLinkedInProfiles === "KEY_SKIP_LINKEDIN_PROFILE" ||
    hideLinkedInProfiles === "KEY_LI_API_FAIL"
  ) {
    return (
      <Profile360
        key={searchByTimeLine.search}
        setHIdeLinkedInProfiles={setHIdeLinkedInProfiles}
      />
    );
  } else if (
    hideLinkedInProfiles === "KEY_SIMILAR_PROFILE_BTN" ||
    (hideLinkedInProfiles === "KEY_DEFAULT" && !isLinkedInSelectionSkip)
  ) {
    return (
      <GlobalSearch
        key={searchByTimeLine.search}
        setHIdeLinkedInProfiles={setHIdeLinkedInProfiles}
      />
    );
  } else {
    return (
      <Profile360
        key={searchByTimeLine.search}
        setHIdeLinkedInProfiles={setHIdeLinkedInProfiles}
      />
    );
  }
}

export default CustomerProfile360;
